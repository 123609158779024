import React, { Component } from "react";
import { Lightbox } from "react-modal-image";
import Masonry from "react-masonry-component";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import SectionContainer from "../components/SectionContainer/SectionContainer";
import HeroSection from "../components/heroSection/heroSection";
import mq from "../utils/mq";

const StyledSectionContainer = styled(SectionContainer)`
  .showcase {
    //margin: 0 auto;
    //width: 100%;
  }
`;

const ImgContainer = styled.div`
  height: auto;
  width: 40%;
  cursor: pointer;
  padding: 5px;

  ${mq.a768} {
    width: 30%;
    padding: 10px;
  }

  ${mq.a1200} {
    width: 20%;
  }
`;

const masonryOptions = {
  // transitionDuration: 0,
  isFitWidth: true,
};

class EventPost extends Component {
  state = {
    modalIsOpen: false,
    galleryIndex: 0,
  };

  toggleLightbox = index => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      galleryIndex: index,
    });
  };

  render() {
    let { data } = this.props;
    // const { modalIsOpen, galleryIndex } = this.state;
    // const { gallery, heroSection } = data.markdownRemark.frontmatter;
    return (
      <Layout>
        {/*<HeroSection data={heroSection} />*/}
        {/*<StyledSectionContainer>*/}
        {/*  <Masonry className="showcase" options={masonryOptions}>*/}
        {/*    {gallery.map((i, index) => {*/}
        {/*      const { fluid } = i.image.childImageSharp;*/}
        {/*      return (*/}
        {/*        <ImgContainer*/}
        {/*          key={index}*/}
        {/*          onClick={() => this.toggleLightbox(index)}*/}
        {/*        >*/}
        {/*          <Img fluid={fluid} style={{ height: "100%" }} />*/}
        {/*        </ImgContainer>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*{modalIsOpen && (*/}
        {/*  <Lightbox*/}
        {/*    large={gallery[galleryIndex].image.childImageSharp.fluid.src}*/}
        {/*    onClose={() => this.toggleLightbox(0)}*/}
        {/*  />*/}
        {/*)}*/}
        {/*</Masonry>*/}
        {/*</StyledSectionContainer>*/}
      </Layout>
    );
  }
}

export default EventPost;

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heroSection {
          title
          description
          heroImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        #        gallery {
        #          image {
        #            childImageSharp {
        #              fluid(maxWidth: 500) {
        #                ...GatsbyImageSharpFluid_withWebp_tracedSVG
        #              }
        #            }
        #          }
        #        }
      }
    }
  }
`;
